import React from 'react'
import Helmet from 'react-helmet'
import './event.scss'
import ReactGA from 'react-ga'
import Head from '../../components/head'
import Foot from '../../components/foot'
import { Link } from "gatsby"
import EventSchema from '../../components/EventSchema/EventSchema'
import Layout from "../../components/layout/layout.js"
import { Icon } from 'react-icons-kit'
import { iosArrowBack } from 'react-icons-kit/ionicons/iosArrowBack'
import { ticket } from 'react-icons-kit/entypo/ticket'
import { iosWorldOutline } from 'react-icons-kit/ionicons/iosWorldOutline'
import { androidMap } from 'react-icons-kit/ionicons/androidMap'
import Moment from 'react-moment'
import moment from 'moment'
import 'moment/locale/nb'
class EventPage extends React.Component {
  render() {
    const { pageContext } = this.props
    return (
      <Layout>
        <div className="event-container">
          <Helmet>
            <html lang="nb" />
            <title>
              {`Frekvens - ${pageContext.data.name} @ ${
                pageContext.data.venue_name
              } - ${moment(pageContext.data.start_timestamp).format(
                'YYYY-MM-DD'
              )}`}
            </title>
            <meta
              name="description"
              content={`${pageContext.data.name} @ ${
                pageContext.data.venue_name
              } - ${pageContext.data.price || 0}kr`}
            />
            <link
              rel="canonical"
              href={`https://frekvensapp.com/event/${
                pageContext.data.alternative_id
              }/`}
            />
          </Helmet>
          <Head />
          <div className="event" id="event">
            <div className="event__section">
              <div
                className="event__image event__image--top"
                style={{
                  backgroundImage: `url(${pageContext.data.large_image_url ||
                    pageContext.data.venue_large_image_url})`,
                }}
              />
            </div>
            <div className="event__section">
              <div
                className="event__image event__image--blur"
                style={{
                  backgroundImage: `url(${pageContext.data.large_image_url ||
                    pageContext.data.venue_large_image_url})`,
                }}
              />
            </div>
          </div>
          <div className="event-buttons-text-container">
            <div className="event-buttons-text-container__top-section">
              <div className="event-text-container">
                <div className="event-text-container__button-container">
                  <Link to="/">
                    <div className="event-text-container__button">
                      <Icon icon={iosArrowBack} size={36} />
                    </div>
                  </Link>
                </div>
                <div className="event-text-container__text-container">
                  <div className="event-text-container__text">
                    <h1>
                      {pageContext.data.name} &#64; {pageContext.data.venue_name}
                    </h1>
                  </div>
                  <div className="event-text-container__time">
                    <Moment
                      format="DD MMMM HH:mm"
                      date={pageContext.data.start_timestamp}
                    />
                    &ndash;
                    <Moment
                      format="HH:mm"
                      date={pageContext.data.end_timestamp}
                    />
                  </div>
                  {pageContext.data.price &&
                    pageContext.data.price > 0 && (
                      <div className="event-text-container__price">
                        {Math.round(pageContext.data.price)}&nbsp;
                        {pageContext.data.currency}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="event-buttons-text-container__bottom-section">
              <div className="event-buttons-container">
                <div className="event-buttons-container__buttons">
                  {pageContext.data.tickets_url && (
                    <ReactGA.OutboundLink
                      eventLabel={pageContext.data.tickets_url}
                      to={pageContext.data.tickets_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="event-buttons-container__button event-buttons-container__button--green">
                        <Icon icon={ticket} size="30" />
                        <p className="event-buttons-container__button-text">
                          Billetter
                        </p>
                      </div>
                    </ReactGA.OutboundLink>
                  )}

                  <ReactGA.OutboundLink
                    eventLabel={pageContext.data.url}
                    to={pageContext.data.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="event-buttons-container__button event-buttons-container__button--purple">
                      <Icon icon={iosWorldOutline} size="30" />
                      <p className="event-buttons-container__button-text">
                        Detaljer
                      </p>
                    </div>
                  </ReactGA.OutboundLink>

                  <ReactGA.OutboundLink
                    eventLabel={`https://www.google.com/maps/?q=${
                      pageContext.data.venue_latitude
                    },${pageContext.data.venue_longitude}`}
                    to={`https://www.google.com/maps/?q=${
                      pageContext.data.venue_latitude
                    },${pageContext.data.venue_longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="event-buttons-container__button event-buttons-container__button--orange">
                      <Icon icon={androidMap} size="30" />
                      <p className="event-buttons-container__button-text">Kart</p>
                    </div>
                  </ReactGA.OutboundLink>
                </div>
              </div>
            </div>
          </div>
          <EventSchema
            event={pageContext.data}
            eventId={pageContext.data.alternative_id}
          />
          <div className="event-container__footer-container">
            <Foot />
          </div>
        </div>
      </Layout>
      
    )
  }
}

export default EventPage
